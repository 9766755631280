@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@500;700&family=Raleway:wght@500;700;900&family=Roboto:wght@500;700;900&display=swap");

div.preloader {
  height: 100vh !important;
  width: 100%;
  background: #000000;
  color: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 50;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

div.texts-container {
 
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 300px;
  width: 100%;
  font-size: 2.5rem;
  margin: 2rem 0;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.app-logo {
  height: 150px;
  animation-name: spin;
  animation-duration: 10s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

div.texts-container span {
  font-family: "Raleway", sans-serif;
}

@media screen and (max-width: 991px) {
  div.texts-container {
    font-size: 1.5rem;
    flex-direction: column;
  }
}
