@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@500;700&family=Raleway:wght@500;700;900&family=Roboto:wght@500;700;900&display=swap");

* {
  scroll-behavior: smooth !important;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  user-select: none !important;
}

.bold {
  font-weight: 900;
}

h2,
h3,
h4,
h5,
th {
  font-family: "Raleway", sans-serif !important;
  font-weight: 700;
}

span {
  font-family: "Roboto", sans-serif;
  font-style: italic;
  font-weight: 900;
  text-transform: capitalize;
}

p,
a,
li {
  text-decoration: none;
  font-family: "Quicksand", sans-serif;
  font-weight: 500;
}

::-webkit-scrollbar {
  width: 0;
}

::-webkit-scrollbar {
  background: inherit;
}

header nav a,
footer {
  font-family: "Raleway", sans-serif !important;
  font-weight: 500;
}

nav,
footer {
  background: linear-gradient(120deg, #360033, #0b8793);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

nav a.bold {
  font-size: 1.5rem;
}

a.navbar-brand {
  height: 50px;
}



a.navbar-brand img {
  transition: all 0.1s linear;
  filter: drop-shadow(2px 2px 2px #000);
  height: 100%;
}

button.navbar-toggler {
  border: 2px #ccc solid;
  border-radius: 2rem;
}

div.box {
  color: #fff;
  background: linear-gradient(120deg, #360033, #0b8793);
  background-repeat: no-repeat;
  height: 100vh;
}

.btn-common {
  cursor: pointer;
  margin: 0.2rem;
  position: relative;
  font-size: 1rem;
  padding: 0.5rem;
  font-family: "Quicksand", sans-serif;
  font-weight: 700;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  overflow: hidden;
  border-width: 0;
  outline: none;
  border-radius: 2rem;
  /* background-color: #52c234; */
  background: linear-gradient(120deg, #0f9b0f, #0c690c);
  color: #fff;
  transition: all 0.4s ease-in-out;
  background-size: 200%;

}



footer {
  padding: 0.5rem;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
}

footer > div {
  padding: 1rem 0;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

footer > div > div:nth-child(1) {
  flex-grow: 1;
}

footer span {
  cursor: default;
  font-style: normal;
  color: #ccc;
  font-family: "Quicksand", sans-serif;
  font-size: 0.8rem;
}

/* home page styles */
section.home-box {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

section.home-box > div:nth-child(2) {
  background: rgba(255, 255, 255, 0.02);
  border-radius: 16px;
  padding: 1rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  -webkit-backdrop-filter: blur(1px);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.02);
  margin-bottom: 2rem;
}

div.box {
  padding: 2rem 2.5rem !important;
  display: flex;

  flex-direction: column;

  section.wrapper#services{
    display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  padding: 0.5rem;
  overflow-x: auto;
  scroll-behavior: smooth;
  height: 100vh;
  margin-bottom: 2rem;
  }
}

div.box section.wrapper#projects {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  padding: 0.5rem;
  overflow-x: auto;
  scroll-behavior: smooth;
  height: 100vh;
  margin-bottom: 2rem;
}

div.holder {
  padding: 1rem;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  height: auto;

  background: rgba(255, 255, 255, 0.02);
  border: 1px solid rgba(255, 255, 255, 0.02);
  border-radius: 1rem;
  backdrop-filter: blur(10px);
}

div.holder > div:nth-child(2) {
  min-height: 230px;
  height: auto;
  align-self: flex-start;
}

div.holder div:nth-child(3) svg {
  fill: #ccc !important;
  width: 1.4rem !important;
  height: 1.4rem !important;
  margin: 0 0.2rem;
  filter: drop-shadow(1px 2px 2px #000);
}

div.holder div:nth-child(3) svg g,
path {
  fill: #ccc !important;
}



div.holder div:nth-child(4) {
  margin-bottom: 1rem;
  position: fixed;
  bottom: 0;
  align-self: flex-end;
}

div.image-holder-services,
div.image-holder-projects {
  height: 200px;
}

div.image-holder-projects img,
div.image-holder-services img {
  padding: 0.5rem;
  height: 100%;
}

/* contact form styling */

div.contact-box {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: flex-start;

  overflow-x: auto;
  scroll-behavior: smooth;
  height: 100vh;

  overflow-x: auto;
  scroll-behavior: smooth;
}

div.contact-box > div {
  margin: 1rem;
}

form.form {
  display: flex;
  flex-direction: column;
}

form.form div:nth-child(3) {
  display: flex;
  align-items: flex-start;
}

form.form div:nth-child(4) {
  align-self: flex-end;
}

div.contactForm {
  background: rgba(255, 255, 255, 0.02);
  border-radius: 16px;
  padding: 1rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  -webkit-backdrop-filter: blur(1px);

  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.02);

  /* justify-content: space-around; */
  border-radius: 1rem;
}

div.item {
  margin: 0.5rem 0;
}

div.item input {
  width: 300px;
  align-items: center;
  font-family: "Quicksand", sans-serif;
  border: none;
  border-radius: 16px;
  padding: 4px 9px;
}

div.item label {
  width: 120px;
  display: inline-block;
  font-size: 18px;
}

textarea {
  height: 100px;
  width: 300px;
  border-radius: 1rem;
  padding: 10px 14px;
  border: none;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}

div.item label::after {
  content: ":";
}

div.emailImageBox {
  height: 400px;
}

div.emailImageBox img {
  height: 100%;
  filter: drop-shadow(2px 2px 10px #000);
}

/* about page styles */
div.aboutPhotoHolder {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 350px;
  width: 500px;
}

div.aboutPhotoHolder img {
  height: auto;
  width: auto;
  max-width: 300px;
  max-height: 300px;
  clip-path: circle(40%);
  transform: scale(1.5);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  transition: all 0.8s ease-in-out;
}



div.about {
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: 100vh; */
  overflow: auto;
  margin-bottom: 2rem;
  padding: 1rem;
}

div.about > div:nth-child(2) {
  background: rgba(255, 255, 255, 0.02);
  border-radius: 16px;
  padding: 1rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  -webkit-backdrop-filter: blur(1px);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.02);
  overflow: auto;
  cursor: default;
  width: 500px;
}

@media screen and (max-width: 1290px) {

  div.box{
    padding: 2rem 1rem !important;

    section.wrapper#projects, section.wrapper#services {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  
}

@media screen and (max-width: 991px) {
  

  div.languages div:nth-child(2){
    
    width: 100%;
  }
  
  div.languages div:nth-child(2) a{
    text-align: center;
    display: inline-block;
    width: 100%;
  }


  div.image-holder-services {
    align-self: center;
  }

  div.image-holder-services,
  div.image-holder-projects {
    height: 150px;
  }

  footer > div {
    height: 30px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  a.icon svg {
    font-size: 1.3rem !important;
  }

  div.box section.wrapper#projects ,div.box section.wrapper#services {
    grid-template-columns: repeat(1, 1fr);
    height: 100vh;
    padding: 1rem;
    margin-bottom: 1.5rem;
    
  }

  div.holder {
    height: auto !important;

    min-width: auto;
  }

  div.contact-box {
    align-items: center;
    flex-direction: column;
    height: 100vh;
    padding: 1rem;
    margin-bottom: 1.5rem;
  }

  div.emailImageBox {
    height: 250px;
  }

  div.contactForm {
    height: auto;
  }

  div.contactForm input,
  textarea {
    width: 100%;
  }

  form.form div:nth-child(3) {
    flex-direction: column;
  }

  div.about {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 1rem 0;
  }

  div.about > div:nth-child(1) {
    margin: 2rem 0;

    width: 300px;
  }

  div.about > div:nth-child(2) {
    max-width: 550px;
    width: auto;
  }

  div.aboutPhotoHolder img {
    height: 270px;
  }

  section.home-box > div:nth-child(3) {
    display: flex;
    text-align: center;
    flex-direction: column;
  }

  div.languages {
    padding: 0 !important;
    flex-direction: column;
    height: auto !important;
    align-items: center !important;
    justify-content: center;
  }

  div.languages > div:nth-child(1) {
    padding: 1rem 0.2rem;
    flex-grow: 0 !important;
  }

  div.image-holder-projects {
    align-self: center;
  }
}

/* footer styles */

a.icon {
  margin: 0 0.2rem;

  /* color: #ccc; */
  transition: all 0.2s ease-in-out;
}



a.icon svg {
  fill: red !important;
  filter: drop-shadow(1px 2px 2px #000);
  font-size: 1.7rem;
}

div.languages {
  padding: 1rem;
  height: 100%;
  width: 100%;
  display: flex;

  align-items: flex-end;
  justify-content: center;
}

div.languages > div:nth-child(1) {
  flex-grow: 1;
}

ul.highlights {
  margin-top: 0.5rem;
}

ul.highlights li {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  color: #bfbfbf;
}

ul.highlights li::first-letter {
  text-transform: capitalize;
}

div.dropdown {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 90px;
  right: -0.5rem;
  padding: 0.5rem;
  background: rgba(255, 255, 255, 0.02);
  color: #fff;
  padding: 1rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  -webkit-backdrop-filter: blur(1px);

  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.02);

  border-radius: 1rem;
  transition: all .1s linear;

 animation-name: blink;
 animation-timing-function:linear;
 animation-duration: 1s;
 animation-iteration-count: infinite;
 
}



div.dropdown div {
  margin: 0 0.2rem;
}

div.dropdown{
  transition: 8s all linear;
}


.activeMenu {
  display: block;
  animation-duration: 0.7s;
  animation-name: slide;

  
}

.inactiveMenu {
  display: none;
  animation-duration: 1s;
  animation-name: slide;
  animation-timing-function: ease-in-out;
}

@keyframes slide {
  from {
    transform: translateX(99px);
    
  }

  to {
    transform: translateX(0px);
   
  }
}


@keyframes blink {

  50%{
    opacity: 0.6;
  }
 
}

@media (hover:hover) {

  div.menuItems a:hover {
    filter: drop-shadow(1px 2px 2px #000);
    /* transition: 1s ease-in-out all; */
  }

  div.dropdown:hover,
div.dropdown:active {
  animation: none;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.074);
  border-radius: .5rem;
}

a.icon:hover {
  font-size: 2.3rem;
  color: #fff;
}

div.aboutPhotoHolder img:hover {
  transform: scale(1.6);
}

div.holder div:nth-child(3) svg :hover {
  fill: #fff !important;
}

.btn-common:hover {
  background-position: 200%;

  border-radius: 0.5rem;
}

a.navbar-brand img:hover,
a.navbar-brand img:active,
a.navbar-brand img:focus {
  height: 105%;
}
}



 